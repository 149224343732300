<template>
<div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
	<div id="page-content" v-if="!viLoader">		
		<div class="dispflex">
			<h1 class="hdrtitle" style="margin-top: 0px;">Online Admission</h1>
			 <button type="button" class="backbtn" @click="previousRoute">
               <i class="fa fa-arrow-left"></i>
              </button>
		</div>
		
		<div class="row">				
			<div class="col-lg-12">
				<div class="widjet">
					<div class="widjethdr dispflex">
						<div style="width:auto">
							<span class="iconsect blubg" style="padding-left:10px;"><i class="fas fa-file-export"></i></span>
							<h1>Manage Admissions</h1>
						</div>
						
						<div style="width: 21%;" class="flexitm">
							<span class="has-float-label">
							<select
										class="form-control form-input"
										id="deptlistid"
										@change="getSelectRange"
										v-model="selectAcademicYear"
										>
										<option :value=null>--Select--</option>
										<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
											{{ item.from}} - {{ item.to }}
										</option>
										</select>
										<label for="acyearid">Academic Year <span class="required">*</span></label>
										</span>
						</div>
						<div class="mt-1">
							
						
							<button class="btn gry_bg tooltipt mpopup twoicn" v-b-modal.crtadmissionpop >
								<i class="fas fa-user user1"></i>
									<p class="user2"><i class="fas fa-plus"></i></p>
								<span class="tooltiptext">Create Admission</span>									
							</button>
						</div>	
					</div>	
					<div class="widjetcontent p-3">
						<!-- <div class="form-row ">
							<div class="form-group input-group col-md-12" style="margin:0">						
								<span class="has-float-label">
										<select
										class="form-control form-input"
										id="deptlistid"
										@change="getSelectRange"
										v-model="selectAcademicYear"
										>
										<option :value=null>--Select--</option>
										<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
											{{ item.from}} - {{ item.to }}
										</option>
										</select>
										<label for="acyearid">Academic Year <span class="required">*</span></label>
								</span>
							</div>
						</div> -->
						<div class="countsect p-2 mt-3 mb-3">
							<ul class="dispflex">
								<li style="font-weight:bold">Admission Status: {{ checkStatus > 0 ? 'Open' : 'Closed' }}</li>
								<li><span class="badge contbadge">{{ getTotalNoSeats() }}</span>No. of Seats</li>
								<li><span class="badge contbadge">{{ getTotalSeatsFilled() }}</span>Seats Filled</li>
								<li><span class="badge contbadge">{{ getTotalSeatsAvailable() }}</span>Seats Available</li>
							</ul>
						</div>
						<div>
							<div class="restable">    
								<div class="resrow resheader">
									<!-- <div class="cell">
										Select
									</div> -->
									<div class="cell">
										Classes
									</div>
									<!-- <div class="cell">
										Section
									</div> -->
									<div class="cell">
										No. of Seats
									</div>
									<div class="cell">
										Seats Filled
									</div>
									<div class="cell">
										Seats Available
									</div>
									<!-- <div class="cell">
										Admission Status
									</div> -->
									<div class="cell">
										Annual Fee
									</div>
									<div class="cell">
										Admission Fee
									</div>
									
									<div class="cell">
										Admission Available
									</div>
										<div class="cell">Last Modified By</div>
										<div class="cell">Last Modified Date</div>
										
									<div class="cell">
										Action
									</div>
								</div>
								<div class="resrow" v-for="(data, index) in admissionList" :key="index">
									<!-- <div class="cell chkbox" data-title="Select">
										<label class="custcheckbox">
											<input type="checkbox" />
											<span class="checkmarkchk"></span>
										</label>
									</div> -->
									<div class="cell" data-title="Classes">{{ data.className ? getClassName(data.className) : '-' }}
										
									</div>
									<!-- <div class="cell" data-title="Section">
										{{ data.classSection ? data.classSection : '-' }}
									</div> -->
									<div class="cell" data-title="No. of Seats">
										{{ data.noOfSeats ? data.noOfSeats : '-' }}
									</div>
									<div class="cell" data-title="Seats Filled">
										{{ data.seatsFilled ? data.seatsFilled : '-' }}
									</div>
									<div class="cell" data-title="Seat Available">
										{{ data.seatsAvailable ? data.seatsAvailable : '-' }}
									</div>
									<!-- <div class="cell" data-title="Admission Status">
										{{ data.admissionStatus ? data.admissionStatus : '-' }}
									</div> -->
									<div class="cell" data-title="Admission Amount">
										{{ data.amount ? data.amount : 0 }}
									</div>
									<div class="cell" data-title="Admission Amount">
										{{ data.admissionFees ? data.admissionFees : '-' }}
									</div>
									
									<div class="cell" data-title="Admission Amount" :style="data.isShowSearch ? 'color : lightgreen;' : 'color : darkorange'">
										{{ data.isShowSearch ? 'Yes' : 'No' }}
									</div>
									<div class="cell" data-title="Seats Filled">
										{{ data.updatedBy ? data.updatedBy.firstName + " " +  data.updatedBy.lastName : '' }}
									</div>
									
										<div  class="cell" data-title="Role">
										{{ data.updatedDate | dateFormat }}
									</div>
									
									<!-- <div class="cell" data-title="Admission search">
										<label class="switch" style="margin-bottom: 0rem">
											<input
											:disabled="data.amount == 0 || data.amount == '' || data.amount == null "
											type="checkbox"
											id="sibtogBtn"
											v-model="data.isShowSearch"
											/>
											<div class="slider"></div>
										</label>
									</div> -->
									<div class="cell mpopup" data-title="Edit" >
										<!-- <label class="switch">
										<input type="checkbox" id="togBtn" />
										<div class="slider"></div>
										</label> -->
										 <b-dropdown id="dropdown-1" text="" style="margin-right:3px">
											<b-dropdown-item  style="width: 125%;" :disabled="data.amount == 0 || data.amount == '' || data.amount == null" @click.prevent="updateAvaiableAdmission(data._id,data.isShowSearch)">{{ data.isShowSearch ? 'Admission Closed' : 'Admission Open' }}</b-dropdown-item>
										</b-dropdown>
										<button
											class="btn round orange tooltipt"
											@click.prevent="openEditPopUp(index)"
											>
											<i class="fas fa-edit"></i
											><span class="tooltiptext">Edit</span>
										</button>

											<button class="btn round red tooltipt" @click.prevent="showDeleteModal(data._id)"  >
											<i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
											</button>
									</div>
									
								</div>		
							</div>
						</div>
					</div>
					<div class="text-center mt-4" v-if="admissionList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <!-- class="active"  -->
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
              </div>
					<!--<div class="widjetfooter text-right dk_iconsml">							
						
							<button type="button" class="btn btnsml" @click="previousRoute">
								<i class="fa fa-arrow-left"></i> <span>Back</span>
							</button>-->
							<!-- <button type="button" class="btn btnsml" ><i class="far fa-clone"></i> <span>Clone</span></button>
							<button type="button" class="btn btnsml" ><i class="far fa-trash-alt"></i> <span>Delete</span></button> -->
					<!--</div>-->
				</div>
			</div>
		</div>
	</div>
	<div>

	<b-modal id="crtadmissionpop" class="modal" no-close-on-backdrop no-close-on-esc>
		<button type="button" class="close"  @click.prevent="closePoup">×</button>

		<div class="contact-form">
		  <!-- <span class="close">&times;</span> -->
		  <h2>Create Admission</h2><br>
		  <div>
			<form data-vv-scope="admissionValidate" id="crtadmission" accept-charset="utf-8">				
				<div class="form-row ">
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							 <select
							   @change="setAdmissionDate(admission.academicYear)"
							    v-validate="'required'"
								class="form-control form-input"
								id="deptlistid"
								name="acadYr"
								v-model="admission.academicYear"
							>
							<option :value=null>--Select--</option>
							<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
								{{ item.from}} - {{ item.to }}
							</option>
							</select>
							<label
								v-if="errors.first('admissionValidate.acadYr')"
								class="vi-error"
								style="top:-1em"
								>Academic year is required</label
							>
							<label for="deptid" v-else >Academic Year <span class="required">*</span></label>

							
						</span>
					</div>
					<!-- <div class="form-group input-group col-md-6 mt-3 ">						
						<span class="has-float-label">
							 <select
								v-model="admission.admissionStatus"
								class="form-control form-input"
								id="admstatusid"
								name="status"
								v-validate="'required'"
								>
								<option
									v-for="(item, index) in statusList"
									:key="index"
									:value="item"
								>
									{{ item }}
								</option>
                             </select>
							<label
								v-if="errors.first('admissionValidate.status')"
								class="vi-error"
								style="top:-1em"
								>Admission status is required</label
							>
							<label v-else for="admstatusid">Admission Status <span class="required">*</span></label>
						</span>
					</div> -->
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							<input type="date" onkeydown="return false" :min="minDateForAdmission" :max="maxDateForAdmission" name="deadLine" v-validate="'required'"  v-model="admission.admissionDeadline" class="form-control form-input" id="addlid" placeholder="Admission Deadline" >
							<label
								v-if="errors.first('admissionValidate.deadLine')"
								class="vi-error"
								for="emailid"
								style="top:-1em"
								>Admission Close Date is required</label
							>
							<label for="addlid" v-else
								>Admission Close Date <span class="required">*</span></label
							>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3" style="border-bottom:1px solid #e5e5e5">	
					<span class="has-float-label">
						             
                            <v-select class="form-control form-input"
                              label="label"
							  name="AssignClasses"
							  v-validate="'required'"
							  multiple
                              v-model="admission.assignedClasses"
                              :options="assignedClassList"
                              :reduce="label => label.value"
                            ></v-select>

							<label
								v-if="errors.first('admissionValidate.AssignClasses')"
								class="vi-error"
								style="top:-1em"
								>Assign classes is required and select minimum one </label
							>
					
							<label  v-else for="addlid">Assign Classes <span class="required">*</span></label>
						</span>
                         
					 </div>
				</div>
				<div class="form-row " id="editform" style="display:none;">
					
					
					<div class="form-group input-group col-md-6 mt-3  pr-md-3">						
						<span class="has-float-label">
							<input list="classname" value="" class="form-control form-input" id="clsnameid" placeholder="Class Name" required="required">
							<label for="clsnameid">Class Name <span class="required">*</span></label>
							<datalist id="classname">
								<option value="Pre-KG">Pre-KG</option>
								<option value="LKG">LKG</option>										
								<option value="UKG">UKG</option>
							</datalist>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3 ">						
						<span class="has-float-label">
							<input list="classsect" value="" class="form-control form-input" id="clsectid" placeholder="Class Section" required="required">
							<label for="clsectid">Class Section <span class="required">*</span></label>
							<datalist id="classsect">
								<option value="Sec - A">Sec - A</option>
								<option value="Sec - B">Sec - B</option>										
								<option value="Sec - C">Sec - C</option>
							</datalist>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3  pr-md-3">						
						<span class="has-float-label">
							<input type="number" name="totseats" value="" class="form-control form-input" id="totseatsid" placeholder="Total No. of Seats" required="required">
							<label for="totseatsid">No. of Seats <span class="required">*</span></label>
						</span>
					</div>
					
					<div class="form-group input-group col-md-6 mt-3">	
						<span class="has-float-label">
							<input type="number" name="maxapp" value="" class="form-control form-input" id="maxappid" placeholder="Max. No. Of Application" required="required">
							<label for="maxappid">Max. No. Of Application <span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							<input type="number" name="seatsavail" value="" class="form-control form-input" id="seatsavailid" placeholder="No. Of Seats Available" required="required">
							<label for="seatsavailid">No. Of Seats Available<span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							<input type="number" name="seatsfilled" value="" class="form-control form-input" id="seatsfilledid" placeholder="Seats Filled" required="required">
							<label for="seatsfilledid">Seats Filled <span class="required">*</span></label>
						</span>
					</div>
					<div class="blutitle col-md-12">Eligibility Criteria</div>
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							<input list="chooseclass" value="" class="form-control form-input" id="chsclsid" placeholder="Choose Class" required="required">
							<label for="chsclsid">Choose Class <span class="required">*</span></label>
							<datalist id="chooseclass">
								<option value="Pre-KG">Pre-KG</option>
								<option value="LKG">LKG</option>										
								<option value="UKG">UKG</option>
							</datalist>
						</span>
					</div>
					</div>
				<div class="widjetfooter ">	
					
					<div class="text-center dk_iconsml">
						<button type="button" class="btn btnsml" id="svbtn"  @click.prevent="createAdmission">  <span>Save</span></button>
						<button type="button" @click.prevent="closePoup" class="btn btncl clsmdl" id="clbtn">   <span>Cancel</span></button>							
					</div>
				</div>
			</form>
		  </div>
		  <div style="display:none;">
			<form action="" method="post" id="createfield" accept-charset="utf-8">				
				<div class="form-row ">
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							<input type="text" name="lblname" value="" class="form-control form-input" id="lblid" placeholder="Label Name" required="required">
							<label for="lblid">Label Name <span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3 ">						
						<span class="has-float-label">
							<input list="dtypelist" value="" class="form-control form-input" id="dtypeid" placeholder="Data Type" required="required">
							<label for="dtypeid">Data Type <span class="required">*</span></label>
							<datalist id="dtypelist">
								<option value="String">String</option>
								<option value="Number">Number</option>										
								<option value="Boolean">Boolean</option>
							</datalist>
						</span>
					</div>
					<div class="form-group input-group col-md-12 mt-3 pr-md-3" style="border-bottom:1px solid #e5e5e5">	
						<label style="color:#6c757d" class="mr-3">Is Mandatory<span class="required">*</span></label>					
						<label class="custradio" >Yes
						  <input type="radio" name="radio">
						  <span class="checkmark"></span>
						</label>
						<label class="custradio" >No
						  <input type="radio" name="radio">
						  <span class="checkmark"></span>
						</label>
					</div>
				</div>
				<div class="pt-3 text-right dk_iconsml" style="border-top:1px solid #e5e5e5">							
					<button type="button" class="btn btnsml" id="svbtn" onclick="saveForm()">  <span>Save</span></button>
					<button type="button" class="btn btncl clsmdl" id="clbtn">   <span>Cancel</span></button>							
				</div>
			</form>
		  </div>
		</div>
	</b-modal>
	<b-modal id="editadmissionpop" class="modal" no-close-on-backdrop no-close-on-esc>
		<div class="contact-form">
		  <!-- <span class="close">&times;</span> -->
		  <h2>Edit Admission</h2><br>
		  <div>
			<form data-vv-scope="editAdmissionValidate"  id="crtadmission" accept-charset="utf-8">				
				<div class="form-row ">
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							 <select
							  @change="setAdmissionDate(editAdmission.academicYear)"
							    v-validate="'required'"
								class="form-control form-input"
								id="deptlistid"
								name="editacdyr"
								v-model="editAdmission.academicYear"
							>
							<option :value=null >  -- Select --  </option>
							<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
								{{ item.from}} - {{ item.to }}
							</option>
							</select>
							<label
								v-if="errors.first('editAdmissionValidate.editacdyr')"
								class="vi-error"
								style="top:-1em"
								>Academic year is required</label
							>
							<label v-else for="acyearid">Academic Year <span class="required">*</span></label>
							
						</span>
					</div>
					<!-- <div class="form-group input-group col-md-6 mt-3 ">						
						<span class="has-float-label">
							 <select
								v-model="editAdmission.admissionStatus"
								class="form-control form-input"
								name="admstatusId"
								v-validate="'required'"
								>
								<option
									v-for="(item, index) in statusList"
									:key="index"
									:value="item"
								>
									{{ item }}
								</option>
                             </select>
							<label
								v-if="errors.first('editAdmissionValidate.admstatusId')"
								class="vi-error"
								style="top:-1em"
								>Admission Status is required</label
							>
							<label v-else for="admstatusid">Admission Status <span class="required">*</span></label>
							
						</span>
					</div> -->
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">					
						<span class="has-float-label">
							<input type="date" onkeydown="return false" v-validate="'required'" :min="minDateForAdmission" :max="maxDateForAdmission" name="addeadline" v-model="editAdmission.admissionDeadline" class="form-control form-input" id="addlid" placeholder="Admission Deadline" >
							<label
									v-if="errors.first('editAdmissionValidate.addeadline')"
									class="vi-error"
									style="top:-1em"
									>Admission Close Date is required</label
								>
							<label v-else for="addlid">Admission Close Date <span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3">	
						<span class="has-float-label">
							<input type="number" name="maxappEdit" v-validate="'required'" v-model="editAdmission.maxNoApplication" class="form-control form-input" id="maxappid" placeholder="Max. No. Of Application" >
							<label
									v-if="errors.first('editAdmissionValidate.maxappEdit')"
									class="vi-error"
									style="top:-1em"
									>Max. No. Of Application is required</label
								>
							<label v-else for="maxappid">Max. No. Of Application <span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3  pr-md-3">			
						<span class="has-float-label">
							 <v-select class="form-control form-input"
                              label="label"
							  name="className"
							  disabled
                              v-model="editAdmission.className"
                              :options="editAssignedClassList"
                              :reduce="label => label.value"
                            ></v-select>
							
							<label for="clsnameid">Class Name </label>
							
						</span>
					</div>
				</div>
				<div class="form-row " id="editform">
					
					
					
					<!-- <div class="form-group input-group col-md-6 mt-3 ">						
						<span class="has-float-label">
							<input list="classsect"  v-model="editAdmission.classSection" class="form-control form-input" id="clsectid" placeholder="Class Section" >
							<label for="clsectid">Class Section </label>
							<datalist id="classsect">
								<option value="Sec - A">Sec - A</option>
								<option value="Sec - B">Sec - B</option>										
								<option value="Sec - C">Sec - C</option>
							</datalist>
						</span>
					</div> -->
					<div class="form-group input-group col-md-6 mt-3  pr-md-3">						
						<span class="has-float-label">
							<input type="number" v-validate="'required|numeric|min:0'" @input="setSeatsFilled(editAdmission.noOfSeats)" name="totseatsEdit" v-model="editAdmission.noOfSeats" class="form-control form-input" id="totseatsid" placeholder="Total No. of Seats" >
							<label
									v-if="errors.first('editAdmissionValidate.totseatsEdit')"
									class="vi-error"
									style="top:-1em"
									>No. of Seats is required</label
								>
							<label v-else for="totseatsid">No. of Seats <span class="required">*</span></label>
						</span>
					</div>
					
					
					<div class="form-group input-group col-md-6 mt-3">						
						<span class="has-float-label">
							<input type="number" 
							   :max="editAdmission.noOfSeats" 
							   disabled
							    v-validate="{ required: true,regex: /^[a-zA-Z0-9 ,\/.']*$/,max: editAdmission.noOfSeats }"
							   name="seatsavailEdit" 
							   v-model="editAdmission.seatsAvailable" 
							   class="form-control form-input" 
							   id="seatsavailid" 
							   placeholder="No. Of Seats Available" >
						    <label
									v-if="errors.first('editAdmissionValidate.seatsavailEdit')"
									class="vi-error"
									style="top:-1em"
									>No. Of Seats Available is required and max of (No. of Seats) </label
								>
							<label v-else for="seatsavailid">No. Of Seats Available<span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3">						
						<span class="has-float-label">
							<input type="number" disabled :max="editAdmission.seatsAvailable"  v-validate="{ required: true,regex: /^[a-zA-Z0-9 ,\/.']*$/,max: editAdmission.seatsAvailable }" name="seatsfilledEdit" v-model="editAdmission.seatsFilled" class="form-control form-input" id="seatsfilledid" placeholder="Seats Filled" >
							<label
									v-if="errors.first('editAdmissionValidate.seatsfilledEdit')"
									class="vi-error"
									style="top:-1em"
									>Seats Filled is required and max of (No. Of Seats Available)</label
								>
							<label v-else for="seatsfilledid">Seats Filled <span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
					<span class="has-float-label">
					<input  type="number" v-validate="'required|numeric|min:1'" name="amount"  v-model="editAdmission.amount" class="form-control form-input" id="amount" placeholder="amount" >
					<label
						v-if="errors.first('editAdmissionValidate.amount')"
						class="vi-error"
						style="top:-1em"
						>Annual Fee is required</label
					>
					<label v-else for="seatsfilledid">Annual Fee <span class="required">*</span></label>
					</span>
							</div>
							<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
					<span class="has-float-label">
					<input  type="text"  v-model="editAdmission.admissionFees" class="form-control form-input"  placeholder="Admission Fees" >
					
					<label  for="seatsfilledid">Admission Fees</label>
					</span>
							</div>
							
							<div class="form-group input-group col-md-6 pr-md-3 mt-3">
							<div style="border-bottom: 1px solid #e5e5e5; width: 100%">
							<label
								style="color: #6c757d; margin-bottom: 0.2rem"
								class="mr-3"
								>Display Admission in Website?</label
							>
							<label class="switch" style="margin-bottom: 0rem">
								<input
								type="checkbox"
								id="sibtogBtn"
								v-model="editAdmission.isShowSearch"
								/>
								<div class="slider"></div>
							</label>
							</div>
						</div>
					<div class="blutitle col-md-12">Eligibility Criteria</div>
					
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							<input  type="number" v-validate="'required|numeric|min:1'" @input="checkMinimumAge" name="minimumAge" v-model="editAdmission.minimumAgeLimit" class="form-control form-input" id="seatsfilledid" >
							<label
									v-if="errors.first('editAdmissionValidate.minimumAge')"
									class="vi-error"
									style="top:-1em"
									>Minimum Age Limit is required should be a number and is required</label
							>
							<label v-else for="seatsfilledid">Minimum Age Limit <span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-6 mt-3 pr-md-3">						
						<span class="has-float-label">
							<input type="number" :disabled="!editAdmission.minimumAgeLimit" @input="checkMaxmumAge" v-validate="'required|numeric|min:1'" :min="editAdmission.minimumAgeLimit" name="maximumAge" v-model="editAdmission.maximumAgeLimit" class="form-control form-input"  >
							<label
									v-if="errors.first('editAdmissionValidate.maximumAge') || maxAgeError"
									class="vi-error"
									style="top:-1em"
									>Maximum Age Limit is required and should be greater than Minimum Age Limit Value</label
							>
							<label v-else for="seatsfilledid">Maximum Age Limit <span class="required">*</span></label>
						</span>
					</div>
					<div class="form-group input-group col-md-12 mt-md-3">						
						<span class="has-float-label">
							
							<v-select class="form-control form-input"
						      v-validate="'required'"
                              label="label"
							  name="eligibilityCriteriaEdit"
							  multiple
                              v-model="editAdmission.eligibilityCriteria"
                              :options="EligibilityCriteriaList"
                              :reduce="label => label.value"
                            ></v-select>
							<label
									v-if="errors.first('editAdmissionValidate.eligibilityCriteriaEdit')"
									class="vi-error"
									style="top:-1em"
									>Required Proofs is required and select minimum one </label
							>

							<label v-else for="chsclsid">Required Proofs <span class="required">*</span></label>

						</span>
					</div>
					</div>
				<!-- </div> -->
				<div class="widjetfooter ">	
					<!-- <div class="dk_icon">
					</div> -->
					<div class="text-center dk_iconsml">
						<button type="button" class="btn btnsml" id="svbtn"  @click.prevent="updateAdmission">  <span>Update</span></button>
						<button type="button" @click.prevent="closeEditPoup" class="btn btncl clsmdl" id="clbtn">   <span>Cancel</span></button>							
					</div>
				</div>
			</form>
		  </div>
		 
		</div>
	</b-modal>
	<b-modal id="deleteAdmissionPopup" no-close-on-backdrop no-close-on-esc >
		<button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

      <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete this record?
           <div
                class="pt-3 text-center dk_iconsml"
                style="border-top: 1px solid #e5e5e5"
              >
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
				  @click.prevent="confirmDelete"
                >
                    <span>Confirm</span>
                </button>
                <button type="button" @click.prevent="closeDeletePopUp" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
        </div>
      <!-- </div> -->
    </b-modal>
</div>
	<vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
</div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";

export default {
  name: "Admission",
  
  data() {
    return {

      admission : {
		  academicYear: null,
		  admissionStatus: 'Open',
		  admissionDeadline: null,
          assignedClasses: []
      },
	  selectAcademicYear: null,
	  editAdmission: {
			_id: null,
			academicYear: null,
			admissionStatus: '',
			admissionDeadline: null,
			className: null,
			seatsAvailable: null,
			noOfSeats: null,
			amount: null,
			admissionFees: '',
			isShowSearch: false,
			seatsFilled: null,
			eligibilityCriteria: null,
			maxNoApplication: null,  
			minimumAgeLimit: null,
			maximumAgeLimit: null
	  },
	  searchWords: '',
      skip: 0, // set the skip info
      limit: 25, // set the limit info
	  currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
	  viLoader: false,
      totalCount: 0,
	  deleteId: null,
      admissionList : [],
	  cloneAdmissionList: [],
	  allClassList: [],
	  academicYearList: [],
	  classList: [],
	  minDateForAdmission: null,
	  maxDateForAdmission: null,
	  EligibilityCriteriaList: [
		  {
			  label: 'Address Proof',
			  value: 'Address Proof'
	      },
		  {
			  label: 'Identify Proof',
			  value: 'Identify Proof'
	      },
		  {
			  label: 'TC Certificate',
			  value: 'TC Certificate'
	      },
		  {
			  label: 'Residence Proof',
			  value: 'Residence Proof'
	      },
		  {
			  label: 'Birth Certificate',
			  value: 'Birth Certificate'
	      },
		  {
			  label: 'Transfer Certificate',
			  value: 'Transfer Certificate'
	      },
		  {
			  label: 'Parent Aadhaar Card',
			  value: 'Parent Aadhaar Card'
	      },
		  {
			  label: 'Child Aadhar Card',
			  value: 'Child Aadhar Card'
	      },
		  {
			  label: 'Medical Reports',
			  value: 'Medical Reports'
	      },
		  {
			  label: 'Photograph - Child',
			  value: 'Photograph - Child'
	      },
		  {
			  label: 'Photograph - Parents/Guardian',
			  value: 'Photograph - Parents/Guardian'
	      },
		  {
			  label: 'Marksheet/Report card (if applicable)',
			  value: 'Marksheet/Report card (if applicable)'
	      },
		  {
			  label: 'Migration Certificate if any',
			  value: 'Migration Certificate if any'
	      },
		  {
			  label: 'Goverment Proof',
			  value: 'Goverment Proof'
	      }
	  ],
	  statusList: [
		  'Open',
		  'Closed'
	  ],
      options: [
		{ name: 'Vue.js', language: 'JavaScript' },
        { name: 'Adonis', language: 'JavaScript' },
        { name: 'Rails', language: 'Ruby' },
        { name: 'Sinatra', language: 'Ruby' },
        { name: 'Laravel', language: 'PHP' },
        { name: 'Phoenix', language: 'Elixir' }
	  ],
	  maxAgeError: false
    };
  },
  created() {
	   this.getAdmissionList(true);
	   this.getAcademicYearList();
  },
  computed: {
	  styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
    assignedClassList() {
      return this.classList.map(x => {
          return {
            label: x.className,
            value: x._id
          };
        });
    },
  
   editAssignedClassList() {
      return this.allClassList.map(x => {
          return {
            label: x.className,
            value: x._id
          };
        });
    },
	getUserName(){
        let userData = secureUI.sessionGet("user");
        return userData.userDetails.firstName + " " + userData.userDetails.lastName
      },
   checkStatus(){
     const activeStatus = this.admissionList.filter( x => x.isShowSearch)
	 return activeStatus.length
	},
  },
 
  methods: {
	  previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
	async navToSelectedPage(selectedPageNo) {

        this.skip = (this.limit * (selectedPageNo - 1));

        this.countAll = false

        this.getAdmissionList(false);
    },
    checkMinimumAge(){
      this.editAdmission.maximumAgeLimit = null
	},
	checkMaxmumAge(){
		
		if( Number(this.editAdmission.maximumAgeLimit) > Number(this.editAdmission.minimumAgeLimit) ){
         this.maxAgeError = false
		}else{
          this.maxAgeError = true
		}
	},
	
    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getAdmissionList(false);
    },

    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getAdmissionList(false);
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
	setAdmissionDate(acdyear){
      if(acdyear){
        const selectAcdYear = this.academicYearList.find( x => x._id == acdyear);
        
        let start = new Date(selectAcdYear.from)
        let end = new Date(selectAcdYear.to)

        let startmonth = start.getMonth()+1
        let startdate = start.getDate()
        

        if (startdate < 10) {
          startdate = '0' + startdate;
        }
        if (startmonth < 10) {
          startmonth = '0' + startmonth;
        }
        

        this.minDateForAdmission =  start.getFullYear() + "-" + startmonth + "-" + startdate;
       
        let endmonth = end.getMonth()+1

        let enddate = new Date(end.getFullYear(), endmonth, 0).getDate();

        if (endmonth < 10) {
          endmonth = '0' + endmonth;
        }
        
        this.maxDateForAdmission =  end.getFullYear() + "-" + endmonth + "-" + enddate;

      }
    },
	
	getTotalNoSeats(){
			if(this.admissionList.length > 0){
				let totalAmount = 0

				for(let x of this.admissionList){
					if(x.noOfSeats){
					totalAmount = (totalAmount + Number(x.noOfSeats))
				} 
				}
				return totalAmount

			}else{
				return 0
			}
	},
	getTotalSeatsFilled(){
			if(this.admissionList.length > 0){
				let totalAmount = 0

				for(let x of this.admissionList){
					if(x.seatsFilled){
					totalAmount = (totalAmount + Number(x.seatsFilled))
				} 
				}
				return totalAmount

			}else{
				return 0
			}
	},
	getTotalSeatsAvailable(){
			if(this.admissionList.length > 0){
				let totalAmount = 0

				for(let x of this.admissionList){
					if(x.seatsAvailable){
					totalAmount = (totalAmount + Number(x.seatsAvailable))
				} 
				}
				return totalAmount

			}else{
				return 0
			}
	},
    showdrop() {
        var showdp = document.getElementById("showdropmenu");
        if (showdp.style.display === "block") {
          showdp.style.display = "none";
        } else {
          showdp.style.display = "block";
        }
        setTimeout(function () {
              showdp.style.display='none';
          }, 10000);
          return false;
    },
	getClassName(classid){

	  const classDetails =  this.allClassList.find((x) => x._id == classid);
      if(classDetails){
       return classDetails.className  
      }else{
       return ''  
      }
     
    },
	showDeleteModal(id){
	  this.deleteId = null;
      this.deleteId = id
      this.$bvModal.show("deleteAdmissionPopup");
    },
	closeDeletePopUp(){
      this.deleteId = null;
      this.$bvModal.hide("deleteAdmissionPopup");
	},
	getSelectRange(){
     if(this.selectAcademicYear){
		 this.getAdmissionList(false);
	 }else{
		 this.selectAcademicYear = null
		 this.getAdmissionList(false);
	 }
	this.skip = 0
	this.currentPageNumber = 1,
	this.lastPageNumber = 1,
	this.selected = 1,
	this.totalCount = 0,
	this.countAll = true
	},
	setSeatsFilled(noOfSeats){
	   if(noOfSeats){
         if(this.editAdmission.seatsFilled){
			this.editAdmission.seatsAvailable = this.editAdmission.noOfSeats - this.editAdmission.seatsFilled
		 }else{
			this.editAdmission.seatsAvailable = this.editAdmission.noOfSeats
		 }
	   }else{
		       this.editAdmission.seatsAvailable = 0
	   }
    },
    openEditPopUp(index) {
	 const editAdmissionDetails = this.admissionList[index];
	 
     this.editAdmission._id = editAdmissionDetails._id ? editAdmissionDetails._id : null
	 this.editAdmission.academicYear = editAdmissionDetails.academicYear ? editAdmissionDetails.academicYear : null
	 this.editAdmission.admissionStatus = editAdmissionDetails.admissionStatus ? editAdmissionDetails.admissionStatus : null
	 this.editAdmission.admissionDeadline = editAdmissionDetails.admissionDeadline ? editAdmissionDetails.admissionDeadline.substring(0,10) : null
	 this.editAdmission.className = editAdmissionDetails.className ? editAdmissionDetails.className : ''
	 this.editAdmission.noOfSeats = editAdmissionDetails.noOfSeats ? editAdmissionDetails.noOfSeats : ''
	 this.editAdmission.eligibilityCriteria = editAdmissionDetails.eligibilityCriteria ? editAdmissionDetails.eligibilityCriteria : ''
	 this.editAdmission.maxNoApplication = editAdmissionDetails.maxNoApplication ? editAdmissionDetails.maxNoApplication : ''
     this.editAdmission.amount = editAdmissionDetails.amount ? editAdmissionDetails.amount : null
	 this.editAdmission.isShowSearch = editAdmissionDetails.isShowSearch ? true : false
	 
	 this.editAdmission.admissionFees = editAdmissionDetails.admissionFees ? editAdmissionDetails.admissionFees : ''
	 this.editAdmission.minimumAgeLimit = editAdmissionDetails.minimumAgeLimit ? editAdmissionDetails.minimumAgeLimit : ''
	 this.editAdmission.maximumAgeLimit = editAdmissionDetails.maximumAgeLimit ? editAdmissionDetails.maximumAgeLimit : ''
     this.getSeatsFilled(editAdmissionDetails.className);
	 this.setAdmissionDate(editAdmissionDetails.academicYear)
	 this.$bvModal.show('editadmissionpop')	
    },
	closePoup(){

		this.$bvModal.hide("crtadmissionpop");
		this.admission.academicYear = null;
		this.admission.admissionStatus = 'Open';
		this.admission.admissionDeadline = null;
		this.admission.assignedClasses = []
        
    },
	closeEditPoup(){
            this.$bvModal.hide('editadmissionpop')	
			this.editAdmission._id =  null;
			this.editAdmission.academicYear = null;
			this.editAdmission.admissionStatus = '';
			this.editAdmission.admissionDeadline = null;
			this.editAdmission.className = null;
			this.editAdmission.seatsAvailable = null;
			this.editAdmission.noOfSeats = null;
			this.editAdmission.seatsFilled = null;
			this.editAdmission.eligibilityCriteria = null;
			this.editAdmission.maxNoApplication = null
			
	},
	async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/contact/getClassList",
          userData.token
        );
        if (response.isSuccess) {
		  this.classList = []

		  const allClassListDetails = secureUI.secureGet(response.data);

          this.allClassList = allClassListDetails;
          
          const unSelectedClass = []
          const admissionClasIds = this.admissionList.map( x => x.className)
		   if(admissionClasIds.length > 0){
			 for(let y of admissionClasIds){
				for(let x of allClassListDetails){
				if(admissionClasIds.indexOf(x._id) == -1 && unSelectedClass.indexOf(x._id) == -1){
				this.classList.push(x)
				unSelectedClass.push(x._id)
				}
				}
			 }
			}else{
			this.classList = allClassListDetails;
			}

        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
	async updateAdmission() {

        let isFormValid = false;
		
		await this.$validator.validateAll('editAdmissionValidate').then(result => {
			isFormValid = result;
		});

		if(isFormValid && !this.maxAgeError) {

		let userData = secureUI.sessionGet("user");

		if (!userData) {
			this.$toasted.error("Please login and do the action");
			this.$router.push('/login');
		} else {
			console.log("this.editAdmission",this.editAdmission.seatsAvailable)
			//this.viLoader = true;

			if(this.editAdmission.seatsAvailable > 0){
				const response = await ViService.viXPost("/admission/updateAdmission", this.editAdmission,userData.token);

					if (response.isSuccess) {
						this.closeEditPoup();
						this.skip = 0
						this.currentPageNumber = 1,
						this.lastPageNumber = 1,
						this.selected = 1,
						this.totalCount = 0,
						this.countAll = true,
						this.getAdmissionList(false);
						this.$toasted.success(response.message);
					} else {
						this.$toasted.error(response.message);
						if(response.message == 'Your session has expired, please login'){
						localStorage.removeItem('user');
						this.$router.push('/login');
					}
					}
			}else{
          	this.$toasted.error('Please increase No. of Seats');

			}
			
		}
		}else{
			this.$toasted.error('Please fill all the required details');
		}
    },
	async updateAvaiableAdmission(id,status) {
     
	  let obj = {
		  isShowSearch : status ? false : true
	  }
	  
      let userData = secureUI.sessionGet("user");

		if (!userData) {
			this.$toasted.error("Please login and do the action");
			this.$router.push('/login');
		} else {
		//	this.viLoader = true;
			const response = await ViService.viXPut(`/admission/updateAvaiableAdmissionStatus/${id}`,obj,userData.token);

			if (response.isSuccess) {
				this.skip = 0
				this.currentPageNumber = 1,
				this.lastPageNumber = 1,
				this.selected = 1,
				this.totalCount = 0,
				this.countAll = true,
				this.getAdmissionList(false);
				const message = status ? 'In-Active Successfully' : 'Active Successfully'
				this.$toasted.success(message);
			} else {
				this.$toasted.error(response.message);
				 if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
			}
		}
    },
    async getAdmissionList(value) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

        if(value){
           this.viLoader = true;
		}

        const response = await ViService.viXGet(`/admission/getAdmissionListView?academicYear=${this.selectAcademicYear}&skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,userData.token);

		if (response.isSuccess) {
			 const resultData = secureUI.secureGet(response.data);

			this.admissionList = resultData.admissionList

			if(this.countAll){
				this.totalCount = resultData.totalCount;
				this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
			}

		  this.getClassList();
          this.$toasted.success(response.message);

        } else {
            this.$toasted.error(response.message);
			 if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        } 
		this.viLoader = false;
      }
    },

    async getSeatsFilled(classId) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

         const response = await ViService.viXGet(`/admission/getSeatFilled?classId=${classId}`,userData.token);

		if (response.isSuccess) {
           const studentCount = secureUI.secureGet(response.data);

		   if(this.editAdmission.noOfSeats){
			   if(studentCount){
				   this.editAdmission.seatsFilled = studentCount
				   this.editAdmission.seatsAvailable = this.editAdmission.noOfSeats - studentCount
			   }
		   }else{
			   this.editAdmission.seatsFilled = studentCount
			   this.editAdmission.seatsAvailable = 0
		   }

        } else {
            this.$toasted.error(response.message);
			 if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        } 
      }
    },
	async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
	async createAdmission() {

       let isFormValid = false;

       await this.$validator.validateAll('admissionValidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
       // this.viLoader = true;
        const response = await ViService.viXPost("/admission/createAdmission", this.admission,userData.token);

		if (response.isSuccess) {
			this.closePoup();
			this.selectAcademicYear = null;
			this.skip = 0
			this.currentPageNumber = 1,
			this.lastPageNumber = 1,
			this.selected = 1,
			this.totalCount = 0,
			this.countAll = true,
            this.getAdmissionList(false);
            this.$toasted.success(response.message);

			let getCounts = this.$store.getters.getAdmissionObjCounts
            getCounts.admission = getCounts.admission + 1

            this.$store.dispatch('setAdmissionObjCOunt', getCounts);

        } else {
            this.$toasted.error(response.message);
			 if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
	  }
    },
    async confirmDelete() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(`/admission/deleteAdmission/${this.deleteId}`,userData.token);

		if (response.isSuccess) {
			this.$bvModal.hide("deleteAdmissionPopup");
			this.deleteId = null;
			 this.skip = 0
			this.currentPageNumber = 1,
			this.lastPageNumber = 1,
			this.selected = 1,
			this.totalCount = 0,
			this.countAll = true,
            this.getAdmissionList(false);

			let getCounts = this.$store.getters.getAdmissionObjCounts
            getCounts.admission = getCounts.admission - 1

            this.$store.dispatch('setAdmissionObjCOunt', getCounts);

            this.$toasted.success(response.message);
        } else {
            this.$toasted.error(response.message);
			 if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  },
  components: {
	  ViSpinner,
      "v-select": Select
    }
};
</script>